<template>
<div>
<div>
<ul class="list-group">
  <li class="list-group-item" v-for="(manageList, idx) in manageLists " :key="manageList.id">{{idx}} {{manageList.membername}} {{manageList.dept}}</li>
</ul>

  </div>
  <paginate
    :page-count= pageTotalCount
    :container-class="pagination"
    :prev-text="prev"
    :next-text="next"
    :click-handler="clickCallback"
  >
  </paginate>
  </div>
</template>

<script>
import manageService from "../services/manageService";
import Paginate from 'vuejs-paginate-next';
export default {
  components: {
    paginate: Paginate,
  },
   data() {
      return {
        page: 10,
        current_page: 0,
        manageLists : {},
        manageListLimit: {},
        pageTotalCount : 0,
      };
    },
  methods: {
    clickCallback (pageNum){
      const startPage =  ( pageNum-1 ) * this.page;
      const endPage =  (pageNum * this.page )-1;
      this.manageLists = this.manageListLimit.slice(startPage,endPage);

    }
  },
  created(){
      const data = {
        sector: "30",
      };
      manageService
        .getAllList(data)
        .then((response) => {

          this.manageLists = response.data.slice(0,this.page);
          //this.manageLists = response.data;
          this.manageListLimit = response.data;
          this.pageTotalCount =  Math.ceil(response.data.length / this.page);
   
        })
        .catch((e) => {
          console.log(e);
        });    
  }
};
</script>
